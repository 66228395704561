<template>
  <b-row class="match-height">
    <b-col
      lg="7"
      md="6"
    >
      <card-advance-congratulation />
    </b-col>
    <b-col
      lg="5"
      md="6"
    >
      <card-advance-medal />
    </b-col>

  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import CardAdvanceCongratulation from './CardAdvanceCongratulation.vue'
import CardAdvanceMedal from './CardAdvanceMedal.vue'

export default {
  components: {
    BRow,
    BCol,

    CardAdvanceCongratulation,
    CardAdvanceMedal,

  },
}
</script>
